<template>
  <pull-to-refresh @refresh="getFiles" :disabled="loading || $vuetify.breakpoint.mdAndUp">
    <upload-file-dialog v-model="fileUploadOpen" />
    <content-title>
      <template slot="title">
        <div class="d-flex justify-space-between align-center mb-2 mb-sm-0">
          <h1>{{ $t('patientPortal.files.title') }}</h1>
          <v-btn
            rounded
            color="primary"
            :x-small="$vuetify.breakpoint.xsOnly"
            class="py-3 py-sm-0 mb-0 mb-sm-2"
            @click="fileUploadOpen = true"
            ><v-icon :small="$vuetify.breakpoint.xsOnly">mdi-upload</v-icon>
            {{ $t('patientPortal.files.uploadFiles') }}
          </v-btn>
        </div>
      </template>
      <template slot="subtitle">{{ $t('patientPortal.files.subtitle') }}</template>
    </content-title>
    <billing-disabled-banner v-if="isBillingDisabled" />
    <div v-if="loading" class="d-flex justify-center align-center">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div v-else-if="files && files.length" class="w-100 mb-10">
      <file-item
        v-for="file in files"
        :key="file.id"
        @click="downloadFile(file)"
        :file="file"
        @refresh="getFiles"
      />
    </div>
    <div v-else class="w-100 mb-6" style="font-size: 0.8em">
      {{ $t('patientPortal.files.noFiles') }}
    </div>
    <div class="d-flex flex-column align-center w-100 px-4 pb-8 help-container" v-if="!loading">
      <div style="width: fit-content; font-weight: bold">
        {{ $t('patientPortal.files.helpTitle') }}
      </div>
      <div style="width: fit-content; font-size: 0.8em" class="text-center text-xs">
        <i18n path="patientPortal.files.helpSubtitle" tag="span" for="main.supportNumber">
          <copyable-text
            :text="$t('main.supportNumber')"
            style="text-decoration: underline"
            :successMessage="'Phone number copied to clipboard'"
          />
        </i18n>
      </div>
    </div>
  </pull-to-refresh>
</template>
<style lang="scss" scoped>
.help-container {
  align-self: flex-end;
}
</style>
<script>
import moment from 'moment';
import BillingDisabledBanner from '../../components/PatientPortal/BillingDisabledBanner.vue';
import ContentTitle from '../../components/ContentTitle.vue';
import CopyableText from '../../components/CopyableText.vue';
import PullToRefresh from '../../components/PatientPortal/PullToRefresh.vue';
import UploadFileDialog from '../../components/UploadFileDialog.vue';
import FileItem from '../../components/PatientPortal/FileItem.vue';
import { PRETTY_DATE_FORMAT } from '../../constants/moment';
import GET_MEMBER_FILES from '../../graphql/Query/GetMemberFiles.gql';
import GET_URL_FOR_FILE from '../../graphql/Query/GetUrlForFile.gql';

export default {
  name: 'Files',
  data: () => ({
    file: null,
    files: [],
    loading: false,
    open: false,
    fileUploadOpen: false,
  }),

  components: {
    BillingDisabledBanner,
    ContentTitle,
    CopyableText,
    FileItem,
    PullToRefresh,
    UploadFileDialog,
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    async getFiles() {
      this.loading = true;

      const { id, providerId } = this.$store.getters['patientPortal/currentUser'];
      try {
        const { data } = await this.$apollo.query({
          query: GET_MEMBER_FILES,
          variables: {
            memberId: id,
            providerId,
          },
          fetchPolicy: 'no-cache',
        });
        const files = data.getMemberFiles || [];
        this.files = files.sort((a, b) => b.createdAt - a.createdAt);
      } catch {
        this.$store.commit('setNotification', {
          color: 'error',
          text: this.$t('patientPortal.files.error'),
          showing: true,
          timeout: 10000,
        });
      }

      this.loading = false;
    },
    async downloadFile(item) {
      this.loading = true;

      const { data } = await this.$apollo.query({
        query: GET_URL_FOR_FILE,
        variables: {
          providerId: this.$store.getters['patientPortal/providerId'],
          fileId: item.id,
        },
        fetchPolicy: 'no-cache',
      });

      const a = document.createElement('a');
      a.setAttribute('download', item.fileName);
      a.setAttribute('target', '_blank');
      a.setAttribute('href', data.getUrlForFile);
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.remove();

      this.loading = false;
    },
    prettyDate(time) {
      return moment.unix(time).format(PRETTY_DATE_FORMAT);
    },
  },
  computed: {
    isBillingDisabled() {
      return this.$store.getters['patientPortal/isBillingDisabled'];
    },
  },
  watch: {
    fileUploadOpen(val) {
      if (val) return;
      this.getFiles();
    },
  },
};
</script>
